'use strict';

$(() => {
    require('./pageDesigner/banner').init();
    require('./pageDesigner/iframe').init();
    require('./pageDesigner/fashionInspirationSlider').init();
    require('./pageDesigner/inspirationTiles').init();
    require('./pageDesigner/ravotclub').init();
    require('./pageDesigner/fashionated/fashionatedSlider').init();
});
