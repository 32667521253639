'use strict';

const Banner = {};

Banner.init = () => {
    if (!$('.pd-banner-2').length) return;

    Banner.initActiveBanner();
    Banner.onLabelHover();
};

Banner.initActiveBanner = () => {
    Banner.setActiveBanner($('.pd-banner-2 .experience-assets-banner2Item:first-of-type .pd-banner-2-item'));
};

Banner.removeCurrentActiveBanner = ($banner) => {
    $banner.parents('.pd-banner-2').find('.pd-banner-2-item--active').removeClass('pd-banner-2-item--active');
};

Banner.setActiveBanner = ($banner) => {
    $banner.addClass('pd-banner-2-item--active');
};

Banner.switchActiveBanner = ($banner) => {
    Banner.removeCurrentActiveBanner($banner);
    Banner.setActiveBanner($banner);
};

Banner.onLabelHover = () => {
    let timeoutId;
    $('body').on('mouseenter', '.pd-banner-2-item__label', (event) => {
        const $banner = $(event.currentTarget).parent('.pd-banner-2-item');
        timeoutId = setTimeout(() => {
            Banner.switchActiveBanner($banner);
        }, 200);
    });
    $('body').on('mouseleave', '.pd-banner-2-item__label', () => {
        clearTimeout(timeoutId);
    });
};

module.exports = Banner;
