'use strict';

const Dialog = require('../util/dialog');

const Ravotclub = {};

Ravotclub.init = () => {
    setTimeout(() => {
        Ravotclub.openHeader();
    }, 1000);
    Ravotclub.onHoneycombMoreInfoClick();
};

Ravotclub.openHeader = () => {
    $('.js-ravotclub-header').addClass('pd-ravotclub-header--open');
};

Ravotclub.onHoneycombMoreInfoClick = () => {
    $('.js-honeycomb').click((e) => {
        const $modalContent = $(e.currentTarget).find('.pd-honeycomb__modal-content');
        Dialog.show(Dialog.TYPE_RAVOTCLUB_INFO, null, 'ravotclub', {}, null, $modalContent.html());
    });
};

module.exports = Ravotclub;
