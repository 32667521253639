'use strict';

const IFrame = {};

/**
 * Adds a listener and sets the iframe element height
 * based on the inner iframe document height and it will also disable scrolling
 */
function addIFrameHeightMessageListener() {
    window.addEventListener('message', function (e) {
        let iFrameElement = $('.pd-iframe').first();
        const whiteListedUrls = $(iFrameElement).data('whitelistedurls');

        if (!iFrameElement || (whiteListedUrls && whiteListedUrls.indexOf(e.origin) === -1)) {
            return;
        }

        const message = e.data;
        iFrameElement.height(message.height);
        iFrameElement.attr('scrolling', 'no');
    }, false);
}

IFrame.init = () => {
    addIFrameHeightMessageListener();
};

module.exports = IFrame;
