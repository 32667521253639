'use strict';

const Slider = require('../util/slider');
const IsMobile = require('../util/isMobile');

const InspirationTiles = {};

InspirationTiles.init = () => {
    if (IsMobile.any()) {
        InspirationTiles.initSlider();
    }
};

InspirationTiles.initSlider = () => {
    Slider.initializeSlider('.js-inspiration-slider', 1.7, false, 1, null, null, true);
};

module.exports = InspirationTiles;
