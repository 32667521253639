'use strict';

const Slider = require('../../util/slider');
const IsMobile = require('../../util/isMobile');

const fashionatedSlider = {};

fashionatedSlider.init = () => {
    fashionatedSlider.initSlider();
    fashionatedSlider.initProductSlider();
};

fashionatedSlider.initSlider = () => {
    const slidesAmount = IsMobile.any() ? 1.2 : 1;
    Slider.initializeSlider('.fashionated-slider', slidesAmount, !IsMobile.any(), 1, null, null, false, !IsMobile.any());
};

fashionatedSlider.initProductSlider = () => {
    const slidesAmount = IsMobile.mobile() ? 1.5 : 2.5;
    Slider.initializeSlider('.fashionated-product-slider', slidesAmount, true, 1, null, null, true, false);
};

module.exports = fashionatedSlider;
